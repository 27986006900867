import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EmailIcon from '@material-ui/icons/Email';
import UserPropType from 'shared/config/userPropType';
import debounce from 'lodash/debounce';
import { ProductHistoryPropType } from '../../../ProductHistoryPropType';
import { verifySAPAccount } from '../../../../../utils/verifySAP';
import {
  ADDING_TO_CART,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_SUCCESS,
  UPDATING_CART,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILURE,
  LOADING_CART,
} from '../../../../Cart/actions/actionTypes';

import '../../ProductHistoryPage.scss';

export const ReorderButton = ({
  children,
  handleClick,
  secondary,
  disabled,
}) => (
  <Button
    className="product-history__table-button"
    data-test="reorder-button-child"
    onClick={handleClick}
    variant={secondary ? 'outlined' : 'contained'}
    disabled={disabled}
  >
    {children}
  </Button>
);

ReorderButton.propTypes = {
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
};

ReorderButton.defaultProps = {
  secondary: false,
  disabled: false,
};

class ReorderOrRequestInfoButton extends Component {
  componentDidUpdate(prevProps) {
    const currentCartStatus = this.props.cart.status;
    const previousCartStatus = prevProps.cart.status;

    if (
      previousCartStatus === UPDATING_CART ||
      previousCartStatus === ADDING_TO_CART
    ) {
      if (
        currentCartStatus === UPDATE_CART_SUCCESS ||
        currentCartStatus === ADD_TO_CART_SUCCESS
      ) {
        this.props.setProductReorderSuccess();
      } else if (
        currentCartStatus === UPDATE_CART_FAILURE ||
        currentCartStatus === ADD_TO_CART_FAILURE
      ) {
        this.props.setProductReorderFailure(
          this.props.cart.error,
          this.props.productHistory.shelf
        );
      }
    }
  }

  reorderProduct = () => {
    const {
      cart: { cartData },
      description,
      productNumber,
      setProductReorderFailure,
      shelf,
      quantity,
      unitOfMeasure,
      user,
      products,
      selectedAccount,
    } = this.props;
    if (!selectedAccount.id) {
      setProductReorderFailure(
        'User does not have a selected account number',
        shelf
      );
    } else {
      let price;
      let netPrice;
      this.props.gtmReorderTopProduct();
      products.data.forEach((product) => {
        product.attributes.variants.forEach((variant) => {
          if (variant.ars_key === productNumber.toString()) {
            // eslint-disable-next-line prefer-destructuring
            price = variant.price;
            netPrice = variant.net_price;
          }
        });
      });
      const cartItem = {
        accountID: selectedAccount.id,
        attributes: { name: description },
        variant: {
          ars_key: productNumber.toString(),
          selectedVolume: quantity,
          unit_measure: unitOfMeasure,
          net_price: netPrice,
          price,
        },
        branchID: selectedAccount.branchId,
        userId: user.userData.id,
        shelf,
      };
      this.props.reorderProduct(cartItem, cartData);
    }
  };

  handleClick = debounce(
    () => {
      const { shelf, cart, setProductReorderFailure } = this.props;
      if (this.isReorderable()) {
        this.reorderProduct();
      } else {
        setProductReorderFailure(cart.error, shelf);
      }
    },
    1000,
    { leading: true, trailing: false }
  );

  requiresExtended = () => {
    const {
      selectedAccount,
      flags: { localizedCatalog, localizedCatalogUs },
    } = this.props;

    return (
      verifySAPAccount(selectedAccount.id) ||
      (selectedAccount.locationCode === 'CAN' && localizedCatalog) ||
      (selectedAccount.locationCode === 'USA' && localizedCatalogUs)
    );
  };

  isReorderable = () => {
    const { extended, shelf, status } = this.props;
    const extendedChecked = this.requiresExtended() ? extended : true;
    return (
      shelf?.toLowerCase() === 'chemical' && extendedChecked && status === 1
    );
  };

  render() {
    const { cart } = this.props;
    const disabled = cart.status === LOADING_CART || cart.status === '';
    if (this.isReorderable()) {
      return (
        <ReorderButton
          data-test="reorder-button"
          handleClick={this.handleClick}
          disabled={disabled}
        >
          <ShoppingCartIcon className="product-history__button-icon" /> Reorder
        </ReorderButton>
      );
    }
    return (
      <ReorderButton
        data-test="reorder-button"
        handleClick={this.handleClick}
        secondary
      >
        <EmailIcon className="product-history__button-icon" />
        <span className="product-history__button-text">Request Info</span>
      </ReorderButton>
    );
  }
}

ReorderOrRequestInfoButton.propTypes = {
  setProductReorderSuccess: PropTypes.func.isRequired,
  setProductReorderFailure: PropTypes.func.isRequired,
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
    locationCode: PropTypes.string,
    branchId: PropTypes.string,
  }),
  gtmReorderTopProduct: PropTypes.func,
  reorderProduct: PropTypes.func.isRequired,
  shelf: PropTypes.string.isRequired,
  productNumber: PropTypes.number.isRequired,
  extended: PropTypes.bool,
  status: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  unitOfMeasure: PropTypes.string.isRequired,
  cart: PropTypes.shape({
    error: PropTypes.string,
    status: PropTypes.string.isRequired,
    cartData: PropTypes.arrayOf(
      PropTypes.shape({
        variant_sku: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    userData: UserPropType.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  productHistory: ProductHistoryPropType.isRequired,
  products: PropTypes.shape({
    data: PropTypes.array.isRequired,
  }).isRequired,
  flags: PropTypes.shape({
    localizedCatalog: PropTypes.bool,
    localizedCatalogUs: PropTypes.bool,
  }).isRequired,
};

ReorderOrRequestInfoButton.defaultProps = {
  gtmReorderTopProduct: () => {},
  selectedAccount: {},
  extended: false,
};

export default ReorderOrRequestInfoButton;
