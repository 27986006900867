import React from 'react';
import queryString from 'query-string';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import { AuthContext, useAuth } from '@nutrien/fe-domain-utils';
import SiteRedirect from 'shared/utils/SiteRedirect';
import MaintenanceNotification from 'shared/ui/MaintenanceNotification';
import { EmployeeSessionChecker } from 'components/Employee';
import LDOverride from 'components/LDOverride';
import SessionChecker from 'components/SessionChecker';
import { useTermsService } from 'services/utilServices/termsCheckerService/termsCheckerService';
import TermsModal from 'components/Terms/TermsModal';
import useIsAuth0Active from 'hooks/useIsAuth0Active';
import useMirroredUserCacheClear from 'hooks/useMirroredUserCacheClear';
import useNativeAuth from 'hooks/useNativeAuth/useNativeAuth';
import {
  useLaunchDarklyIdentifiedCustomer,
  LaunchDarklyContextProvider,
} from 'hooks/useLaunchDarklyIdentifiedCustomer';
import NutrienDataGraphQLProvider from 'shared/lib/NutrienDataGraphQLProvider';
import storage from 'shared/lib/storage';
import AppRouter from './AppRouter';
import { logout } from '../Authentication/actions/authenticationActions';
import HubModalV1 from '../Landing/HubModalV1';
import HubModalV2 from '../Landing/HubModalV2';

import './App.scss';

const App = () => {
  useTermsService();
  const auth = useAuth();
  useLaunchDarklyIdentifiedCustomer();
  useMirroredUserCacheClear();
  const dispatch = useDispatch();
  const { forceLogout, cxhPoisonPill } = useFlags();

  // Hub Modal
  const [dialogV1Open, setDialogV1Open] = React.useState(false);
  const [dialogV2Open, setDialogV2Open] = React.useState(false);
  const queryParms = queryString.parse(window.location.search);

  React.useEffect(() => {
    if (queryParms.showHubModal) {
      setDialogV1Open(true);
    }
  }, [queryParms]);

  React.useEffect(() => {
    if (forceLogout) {
      dispatch(logout());
    }

    if (storage.getItem('hubInterceptDismissed') !== 'true' || cxhPoisonPill) {
      setDialogV2Open(true);
    }
  }, [cxhPoisonPill, forceLogout]);

  const closeDialogV2 = () => {
    if (!cxhPoisonPill) {
      storage.setItem('hubInterceptDismissed', true);
      setDialogV2Open(false);
    }
  };

  return (
    <div className="app__container">
      <LaunchDarklyContextProvider>
        <SiteRedirect />
        <SessionChecker />
        <EmployeeSessionChecker />
        <AppRouter />
        {auth.isAuthenticated && (
          <>
            <HubModalV1
              isOpen={dialogV1Open}
              closeDialog={() => setDialogV1Open(false)}
            />
            <HubModalV2
              isOpen={dialogV2Open}
              closeDialog={() => closeDialogV2()}
              preventDismiss={cxhPoisonPill}
            />
          </>
        )}
        <MaintenanceNotification />
        <TermsModal />
      </LaunchDarklyContextProvider>
    </div>
  );
};

const AuthAppWrapper = () => {
  const isAuth0Active = useIsAuth0Active();
  const nativeAuth = useNativeAuth();

  return (
    <AuthContext.Provider value={{ isAuth0Active, ...nativeAuth }}>
      <NutrienDataGraphQLProvider>
        <App />
      </NutrienDataGraphQLProvider>
    </AuthContext.Provider>
  );
};

export default LDOverride(AuthAppWrapper);
