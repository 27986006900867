import { removeLocalAccessToken } from '@nutrien/fe-domain-utils';
import storage from 'shared/lib/storage';

const removeToken = () => {
  removeLocalAccessToken();
  storage.removeItem('refresh-token');
  storage.removeItem('token-expiration');
  storage.removeItem('is-employee');
  storage.removeItem('fsr-token');
  storage.removeItem('mirrored-user');
  storage.removeItem('hubInterceptDismissed');
};
export default removeToken;
