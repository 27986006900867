/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@nutrien/uet-react/Box';
import Card from '@nutrien/uet-react/Card';
import { Grid, Row, Cell } from 'nutrien-common-components-react/dist/mdc/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Button } from '@material-ui/core';
import resolveHubUrl from 'shared/config/resolveHubUrl';
import {
  gtmRedirectToNewWeb,
  gtmNutrienHubDialog,
  gtmNutrienHubAndroidApp,
  gtmNutrienHubAppleApp,
} from 'store/middleware/TagManager/gtmActions';
import HubPlatforms from '../../../../assets/hub-platforms.png';
import AppleBadge from '../../../../assets/download_AppStore.png';
import GoogleBadge from '../../../../assets/download_GooglePlay.png';
import HubAppModal from './hubAppModal';
import { APPLE_LINK, GOOGLE_LINK } from '../../constants';

import './hubPromotionStyles.scss';

const HubPromotion = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const isTabletDn = useMediaQuery('(max-width: 1023px)'); // tablets
  const isMobile = useMediaQuery('(max-width: 719px)'); // phones

  const platformsImage = () => {
    return (
      <img
        src={HubPlatforms}
        alt="Various devices running the Nutrien Hub app"
        className="ecomm-hub-ad__platform-image"
      />
    );
  };

  const onAppStore = (appStore) => {
    if (appStore === 'apple') {
      dispatch(gtmNutrienHubAppleApp());
    } else if (appStore === 'android') {
      dispatch(gtmNutrienHubAndroidApp());
    }
  };
  const mobileHubButtons = () => {
    return (
      <>
        <a
          href={APPLE_LINK}
          rel="noreferrer noopener"
          target="_blank"
          onClick={() => onAppStore('apple')}
        >
          <img
            src={AppleBadge}
            alt="Click to view the Hub app in the Apple App Store"
            className="ecomm-hub-ad__badge-image"
          />
        </a>
        <a
          href={GOOGLE_LINK}
          rel="noreferrer noopener"
          target="_blank"
          onClick={() => onAppStore('android')}
        >
          <img
            src={GoogleBadge}
            alt="Click to view the Hub app in the Google Play Store"
            className="ecomm-hub-ad__badge-image"
          />
        </a>
      </>
    );
  };

  useEffect(() => {
    if (dialogOpen) {
      dispatch(gtmNutrienHubDialog());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen]);

  const tabletUpHubButtons = () => {
    const handleNewWebClick = () => {
      dispatch(gtmRedirectToNewWeb());
    };
    return (
      <>
        <Button
          className="ecomm-hub-ad__first-button"
          variant="contained"
          href={resolveHubUrl()}
          rel="noopener"
          target="_blank"
          onClick={handleNewWebClick}
        >
          New Web Experience
        </Button>
        <Button
          className="ecomm-hub-ad__second-button"
          variant="outlined"
          onClick={() => setDialogOpen(true)}
        >
          New Nutrien HUB Mobile App
        </Button>
      </>
    );
  };

  return (
    <Card component={Box} height="100%" p={2} data-test="covid-card">
      <HubAppModal
        isOpen={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        onAppStore={onAppStore}
      />
      <div className="ecomm-hub-ad__container">
        <Grid className="ecomm-hub-ad__grid">
          <Cell columns={12} className="ecomm-hub-ad__title-cell">
            <h2 data-test="ecomm-hub-ad-title">Try the New Nutrien HUB!</h2>
          </Cell>

          <Cell desktopColumns={8} tabletColumns={2} phoneColumns={2}>
            <Grid className="ecomm-hub-ad__text-grid">
              {isMobile && (
                <Row>
                  <Cell
                    desktopColumns={4}
                    tabletColumns={8}
                    phoneColumns={4}
                    className="ecomm-hub-ad__platforms-image-container"
                  >
                    {platformsImage()}
                  </Cell>
                </Row>
              )}
              <Row>
                <Cell columns={12} className="ecomm-hub-ad__text-cell">
                  <p className="ecomm-hub-ad__text-p-top">
                    The Digital Hub has a new enhanced experience to make it
                    easier to manage your account, make payments and view
                    agronomically relevant weather. The new experience is called
                    the Nutrien HUB and is available on the web and mobile. We
                    invite you to check out the new Nutrien HUB and provide
                    feedback to{' '}
                    <a href="mailto:digital.feedback@Nutrien.com">
                      Digital.Feedback@Nutrien.com
                    </a>
                  </p>
                  {isTabletDn && !isMobile && platformsImage()}
                </Cell>
              </Row>
            </Grid>
            <Cell className="ecomm-hub-ad__button-container">
              {isMobile && mobileHubButtons()}
              {!isMobile && tabletUpHubButtons()}
            </Cell>
          </Cell>
        </Grid>
        {!isTabletDn && platformsImage()}
      </div>
    </Card>
  );
};

export default HubPromotion;
