import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Box, Button, IconButton } from '@material-ui/core';
import resolveHubUrl from 'shared/config/resolveHubUrl';
import KEY_ENUM from 'shared/utils/KeyCodes';
import HubFieldsPage from 'assets/hub-fields-page.png';
import HubWeatherPage from 'assets/hub-weather-page.png';
import {
  Dialog,
  DialogContent,
  Icon,
} from 'nutrien-common-components-react/dist/mdc';

import './styles.scss';

const HubModalV1 = ({ isOpen, closeDialog }) => {
  const hubUrl = resolveHubUrl();
  const queryParams = queryString.parse(window.location.search);
  const modalType = queryParams?.modalType;

  const title =
    modalType === 'insights'
      ? 'Farm Insights has Moved'
      : 'New Weather Features';

  const subTitle =
    modalType === 'insights'
      ? 'Field level weather conditions are now available in the new Nutrien HUB.'
      : 'Daily Videos, rainfall and more are now in the New Nutrien HUB. ';

  const image = modalType === 'insights' ? HubFieldsPage : HubWeatherPage;

  const handleCloseDialog = () => {
    const newPath = queryString.exclude(window.location.href, [
      'showHubModal',
      'modalType',
    ]);

    window.history.replaceState('', '', newPath);

    closeDialog();
  };

  const linkToHubPage = () => {
    if (modalType === 'insights') {
      window.open(`${hubUrl}/fields`, '_blank');
    }

    if (modalType === 'weather') {
      window.open(`${hubUrl}/weather`, '_blank');
    }
  };

  const LinkOutButton = () => (
    <Box display="flex" paddingTop={2}>
      <Button type="submit" variant="contained" onClick={linkToHubPage}>
        Go to New Nutrien HUB
      </Button>
    </Box>
  );

  return (
    <Dialog className="container" open={isOpen} onClosing={handleCloseDialog}>
      <div className="close-button">
        <IconButton
          aria-label="Click to close Hub Ad Dialog"
          onClick={handleCloseDialog}
          onKeyPress={(event) => {
            if (event.key === KEY_ENUM.ESCAPE || event.key === KEY_ENUM.ENTER) {
              handleCloseDialog();
            }
          }}
          tabIndex="0"
        >
          <Icon icon="close" />
        </IconButton>
      </div>
      <DialogContent className="dialog-content">
        <div className="title">{title}</div>
        <div className="sub-title">{subTitle}</div>
        <img className="image" src={image} alt="" />
        <LinkOutButton />
      </DialogContent>
    </Dialog>
  );
};

HubModalV1.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default HubModalV1;
