import React from 'react';
import propTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
} from 'nutrien-common-components-react/dist/mdc';
import Icon from 'nutrien-common-components-react/dist/mdc/Icon';
import { IconButton } from '@material-ui/core';
import KEY_ENUM from 'shared/utils/KeyCodes';
import GoogleBadge from '../../../../assets/download_GooglePlay.png';
import AppleBadge from '../../../../assets/download_AppStore.png';
import AppleQRCode from '../../../../assets/download_AppStore_QR.svg';
import GoogleQRCode from '../../../../assets/download_GooglePlay_QR.svg';
import HubMobilePlatform from '../../../../assets/hub-mobile-platform.png';
import { APPLE_LINK, GOOGLE_LINK } from '../../constants';
import './hubPromotionStyles.scss';

const HubAppModal = ({ isOpen, closeDialog, onAppStore }) => {
  return (
    <div className="ecomm-hub-app-modal">
      <Dialog open={isOpen} onClosing={() => closeDialog()}>
        <div className="ecomm-hub-app-modal__close-container">
          <IconButton
            aria-label="Click to close Hub Ad Dialog"
            onClick={() => closeDialog()}
            onKeyPress={(event) => {
              if (
                event.key === KEY_ENUM.ESCAPE ||
                event.key === KEY_ENUM.ENTER
              ) {
                closeDialog();
              }
            }}
            tabIndex="0"
          >
            <Icon icon="close" className="ecomm-hub-app-modal__close-icon" />
          </IconButton>
        </div>
        <DialogContent className="ecomm-hub-app-modal__content-container">
          <div className="ecomm-hub-app-modal__download-content">
            <h2>Download the new Nutrien HUB</h2>
            <div className="ecomm-hub-app-modal__qr-codes-container">
              <div className="ecomm-hub-app-modal__apple-links">
                <img
                  src={AppleQRCode}
                  alt="QR code to view the Hub app in the Apple App Store"
                  className="ecomm-hub-app-modal__qr-code"
                />
                <a
                  href={APPLE_LINK}
                  rel="noreferrer noopener"
                  target="_blank"
                  onClick={() => onAppStore('apple')}
                >
                  <img
                    src={AppleBadge}
                    alt="Click to view the Hub app in the Apple App Store"
                    className="ecomm-hub-app-modal__badge-image"
                  />
                </a>
              </div>
              <div className="ecomm-hub-app-modal__google-links">
                <img
                  src={GoogleQRCode}
                  alt="QR code to view the Hub app in the Google Play Store"
                  className="ecomm-hub-app-modal__qr-code"
                />
                <a
                  href={GOOGLE_LINK}
                  rel="noreferrer noopener"
                  target="_blank"
                  onClick={() => onAppStore('android')}
                >
                  <img
                    src={GoogleBadge}
                    alt="Click to view the Hub app in the Google Play Store"
                    className="ecomm-hub-app-modal__badge-image"
                  />
                </a>
              </div>
            </div>
          </div>
          <img
            src={HubMobilePlatform}
            alt="A mobile device running the Nutrien Hub app"
            className="ecomm-hub-app-modal__hub-mobile"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

HubAppModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  closeDialog: propTypes.func.isRequired,
  onAppStore: propTypes.func.isRequired,
};

HubAppModal.defaultProps = {};

export default HubAppModal;
