import React, { useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Box, Button } from '@material-ui/core';
import seedIcon from 'assets/general-seed.svg';
import fertilizerIcon from 'assets/general-fert.svg';
import applicationIcon from 'assets/general-application.svg';
import chemicalIcon from 'assets/general-chem.svg';
import UserPropType from 'shared/config/userPropType';
import { MaterialLoadingIcon, ApiErrorMessage } from 'shared/ui';
import { USER_FETCHED } from 'shared/config/userConstants';
import { currency } from 'shared/utils/numberFormatters';
import {
  formatTodaysDate,
  getFirstDateOfCurrentYear,
} from 'shared/utils/DateHelper';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Icon } from 'nutrien-common-components-react/dist/mdc';

import './PurchasesCard.scss';

const PurchasesCard = ({
  user,
  fetchPurchases,
  setPurchasesFilter,
  setNewTimeframeForProductHistory,
  purchases,
  selectedAccount,
  flags,
}) => {
  const getDefaultOrFirstAccount = () => {
    let defaultAccountNumber = get(
      user,
      'userData.userDetails.defaultAccountNumber'
    );

    // we cannot depend upon the default account number
    // when the default account number is not found
    // use the user's first account number, which should always exist
    if (!defaultAccountNumber) {
      defaultAccountNumber = get(
        user,
        'userData.userDetails.associatedOrgs[0].accounts[0].number'
      );
    }
    return defaultAccountNumber;
  };

  useEffect(() => {
    const { universalHeader } = flags;
    const accountNumber = universalHeader
      ? selectedAccount.number
      : getDefaultOrFirstAccount();

    if (user.status === USER_FETCHED) {
      fetchPurchases(
        accountNumber,
        getFirstDateOfCurrentYear(),
        formatTodaysDate()
      );
    }
  }, [user.status, selectedAccount]);

  const handleClick = (e, shelf) => {
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    setPurchasesFilter(capitalize(shelf));
  };

  const handlePurchaseSinceClick = () => {
    setNewTimeframeForProductHistory('thisyear');
  };

  const renderShelf = (shelf, purchasesByShelf, icon) => {
    let chemicalIconClassname = '';
    if (shelf === 'chemical') {
      chemicalIconClassname = 'purchases-card__shelf-name--chemical';
    }

    return (
      <div
        className="purchases-card__shelf"
        role="button"
        tabIndex={0}
        onClick={(event) => handleClick(event, shelf)}
        onKeyDown={(event) => handleClick(event, shelf)}
        aria-label={`View ${shelf} Purchases`}
      >
        <div className="purchases-card__shelf-name">
          <img src={icon} alt={shelf} className={chemicalIconClassname} />
          {shelf}
        </div>
        <div
          className="purchases-card__shelf-purchase"
          data-test={`purchases-card__shelf-purchase--${shelf}`}
        >
          {currency(purchasesByShelf.toFixed(0))}
        </div>
      </div>
    );
  };

  const renderPurchaseHistory = () => {
    let chemicalPurchases = 0;
    let fertilizerPurchases = 0;
    let seedPurchases = 0;
    let applicationPurchases = 0;

    purchases.list.forEach((item) => {
      const itemShelf = item.shelf?.toLowerCase();

      switch (itemShelf) {
        case 'chemical':
          chemicalPurchases += item.cost;
          break;
        case 'fertilizer':
          fertilizerPurchases += item.cost;
          break;
        case 'seed':
          seedPurchases += item.cost;
          break;
        case 'application':
          applicationPurchases += item.cost;
          break;
        default:
          break;
      }
      // There is also an OTHER shelf. Design has asked to hide for now.
    });

    // TODO: Extract repeated code below into separate component
    return (
      <>
        <div
          className="purchases-card__content-wrapper"
          data-test="purchase-history-wrapper"
        >
          <div className="purchases-card__content--centered">
            <button
              className="purchases-card__icon-wrapper"
              onClick={handlePurchaseSinceClick}
            >
              <Icon
                className="purchases-card__history-icon--oversized"
                data-test="purchases-card__icon-wrapper"
                icon="history"
                type="success"
              />
              Purchased since {getFirstDateOfCurrentYear('MMMM D, YYYY')}
            </button>

            <div>
              {renderShelf('chemical', chemicalPurchases, chemicalIcon)}
              {renderShelf('fertilizer', fertilizerPurchases, fertilizerIcon)}
              {renderShelf('seed', seedPurchases, seedIcon)}
              {renderShelf(
                'application',
                applicationPurchases,
                applicationIcon
              )}
            </div>
          </div>
        </div>
        <Box sx={{ position: 'absolute', bottom: 16 }}>
          <Button variant="text" data-test="purchases-card__view-all-link">
            View All Purchases
            <ChevronRightIcon />
          </Button>
        </Box>
      </>
    );
  };

  const renderContactUsError = () => (
    <ApiErrorMessage
      entityName="Past purchases"
      customMessage={
        <div>
          Please <a href="/contact">contact us</a> for help.
        </div>
      }
    />
  );

  const renderCardContent = () => {
    const { status } = purchases;
    if (status === undefined) {
      return renderContactUsError();
    }

    switch (status.toLowerCase()) {
      case 'loading':
        return <MaterialLoadingIcon data-test="purchases-loading" />;
      // api returns 404 in case of no results, this allows the card to render with 0 dollars
      case 'error':
      case 'ok':
        return (
          <Link
            to="/accounts/product-history"
            className="purchases-card__link-wrapper"
          >
            <span className="purchases-card__title">Purchases</span>
            {renderPurchaseHistory()}
          </Link>
        );
      default:
        return <ApiErrorMessage entityName="Past purchases" />;
    }
  };

  return (
    <Card
      component={Box}
      p={2}
      data-test="purchases-card__wrapper"
      className="purchases-card__wrapper"
    >
      {renderCardContent()}
    </Card>
  );
};

PurchasesCard.propTypes = {
  user: UserPropType.isRequired,
  fetchPurchases: PropTypes.func.isRequired,
  setPurchasesFilter: PropTypes.func.isRequired,
  setNewTimeframeForProductHistory: PropTypes.func.isRequired,
  purchases: PropTypes.shape({
    status: PropTypes.string,
    list: PropTypes.array,
    error: PropTypes.string,
  }),
  selectedAccount: PropTypes.shape({
    number: PropTypes.string,
  }).isRequired,
  flags: PropTypes.shape({
    universalHeader: PropTypes.bool,
  }).isRequired,
};

PurchasesCard.defaultProps = {
  purchases: PropTypes.shape({}),
};

export default PurchasesCard;
